import React from 'react'
import { BsList } from "react-icons/bs";
import Avatar from '../../../../img/teacher-profile-icon.png'

import { logout } from '../../../../auth/authSlice';
import { useDispatch } from 'react-redux';

export default function Header({setActive}) {
    const dispatch = useDispatch();


    const handleLogout = async e => {
        e.preventDefault()
        dispatch(logout());
        window.location.href = "/"
    }
    return (
        <header className="header header-sticky p-0 mb-4">
            <div className="container-fluid border-bottom px-4">
                <BsList className='mob-bars' onClick={e => setActive(true)} />
                {/* <button className="header-toggler" type="button" onclick="coreui.Sidebar.getInstance(document.querySelector('#sidebar')).toggle()" style={{ marginInlineStart: "-14px" }}></button> */}
                {/* <ul className="header-nav d-none d-lg-flex">
                    <li className="nav-item">
                        <a className="nav-link" href="#">
                            Dashboard
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">
                            Users
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">
                            Settings
                        </a>
                    </li>
                </ul> */}
                <ul className="header-nav ms-auto">
                    <li className="nav-item">
                        <a className="nav-link" href="#"></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#"></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#"></a>
                    </li>
                </ul>
                <ul className="header-nav">
                    <li className="nav-item py-1">
                        <div className="vr h-100 mx-2 text-body text-opacity-75"></div>
                    </li>
                    <li className="nav-item dropdown">
                        <button className="btn btn-link nav-link py-2 px-2 d-flex align-items-center" type="button" aria-expanded="false" data-coreui-toggle="dropdown"></button>
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li>
                                <button className="dropdown-item d-flex align-items-center" type="button" data-coreui-theme-value="light">
                                    {" "}
                                    Light
                                </button>
                            </li>
                            <li>
                                <button className="dropdown-item d-flex align-items-center" type="button" data-coreui-theme-value="dark">
                                    Dark
                                </button>
                            </li>
                            <li>
                                <button className="dropdown-item d-flex align-items-center active" type="button" data-coreui-theme-value="auto">
                                    {" "}
                                    Auto
                                </button>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item py-1">
                        <div className="vr h-100 mx-2 text-body text-opacity-75"></div>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link py-0 pe-0" data-coreui-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                            <div className="avatar avatar-md">
                                <img className="avatar-img" src={Avatar} alt="user@email.com" />
                            </div>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end pt-0">
                            <div className="dropdown-header bg-body-tertiary text-body-secondary fw-semibold rounded-top mb-2">Account</div>
                            <a className="dropdown-item" href="#">
                                Updates<span className="badge badge-sm bg-info ms-2">42</span>
                            </a>
                            <a className="dropdown-item" href="#">
                                Messages<span className="badge badge-sm bg-success ms-2">42</span>
                            </a>
                            <a className="dropdown-item" href="#">
                                Tasks<span className="badge badge-sm bg-danger ms-2">42</span>
                            </a>
                            <a className="dropdown-item" href="#">
                                Comments<span className="badge badge-sm bg-warning ms-2">42</span>
                            </a>
                            <div className="dropdown-header bg-body-tertiary text-body-secondary fw-semibold my-2">
                                <div className="fw-semibold">Settings</div>
                            </div>
                            <a className="dropdown-item" href="#">
                                Profile
                            </a>
                            <a className="dropdown-item" href="#">
                                Settings
                            </a>
                            <a className="dropdown-item" href="#">
                                Payments<span className="badge badge-sm bg-secondary ms-2">42</span>
                            </a>
                            <a className="dropdown-item" href="#">
                                Projects<span className="badge badge-sm bg-primary ms-2">42</span>
                            </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="#">
                                Lock Account
                            </a>
                            <a className="dropdown-item" href="#"  onClick={handleLogout}>
                                Logout
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
            {/* <div className="container-fluid px-4">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb my-0">
                        <li className="breadcrumb-item active">
                            <span>Home</span>
                        </li>
                    </ol>
                </nav>
            </div> */}
        </header>
    )
}
