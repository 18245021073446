import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import InputHandler from '../../../../UIComponents/InputHandler'
import SelectBoxHandler from '../../../../UIComponents/SelectBoxHandler'
import CheckBoxHandler from '../../../../UIComponents/CheckBoxHandler'
import RadioButtonHandler from '../../../../UIComponents/RadioButtonHandler'
import TextareaHandler from '../../../../UIComponents/TextareaHandler'
import FileUploadHandler from '../../../../UIComponents/FileUploadHandler'
import FormSubmissionHandler from '../../../../UIComponents/FormSubmissionHandler'
import { postAPI } from '../../../../utils/Api';
import { store } from '../../../../app/store'


export default function Form() {
  const user = store.getState().auth.user;
  console.log(user)
  const { id } = useParams();
  const InitialFormData = {
    id: id, 
    org_id: user?.org_id,
    type: "SCHOOL"
  }
  const [form_data, setFormData] = useState(InitialFormData);
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState({});


  // Radio Button
  const TypeOptions = [{
    label: 'SCHOOL',
    value: 'SCHOOL'
  }, {
    label: 'COMPANY',
    value: 'COMPANY'
  }, {
    label: 'SHOP',
    value: 'SHOP'
  }, {
    label: 'CHANNEL',
    value: 'CHANNEL'
  }]

  useEffect(async () => {
    const data = await postAPI('/Organization/fetchSingle', {id: user?.org_id, requested_domain: user?.requested_domain});
    console.log(data?.data[0])
    setFormData({
      contact_number: data?.data[0]['contact_number'],
      email: data?.data[0]['email'],
      id: data?.data[0]['id'],
      latitude: data?.data[0]['latitude'],
      logo: data?.data[0]['logo'],
      longitude: data?.data[0]['longitude'],
      name: data?.data[0]['name'],
      organization_code: data?.data[0]['organization_code'],
      requested_domain: data?.data[0]['requested_domain'],
      type: data?.data[0]['type'],
      website: data?.data[0]['website'],
    })
  }, [])



  const handleSubmit = async e => {
    e.preventDefault()
    // console.log(form_data)
    const data = await postAPI('/Organization/update', form_data);
  }

  return (
    <>
      <div className='container-fluid'>
        <div className="card mb-3">
          <div className="card-body">
            <h4>{form_data?.organization_code}</h4>
            <FormSubmissionHandler handleSubmit={handleSubmit} editing={editing} error={error}>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col'>
                    <RadioButtonHandler Label="School Type" Options={TypeOptions} Name={"type"} setFormData={setFormData} form_data={form_data}></RadioButtonHandler>
                  </div>
                  <div className='col'>
                    <FileUploadHandler Name="logo" form_data={form_data} setFormData={setFormData} error={error} setError={setError} Section='Logo'></FileUploadHandler>
                  </div>
                </div>
                <div className='container-fluid'>
                  <div className='row'>
                    <div className='col'>
                      <InputHandler form_data={form_data} setFormData={setFormData} Name="name" Type="text" Label="Organization Name"></InputHandler>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      <InputHandler form_data={form_data} setFormData={setFormData} Name="requested_domain" Type="text" Label="Requested Domain"></InputHandler>
                    </div>
                    <div className='col'>
                      <InputHandler form_data={form_data} setFormData={setFormData} Name="email" Type="text" Label="Organization Email"></InputHandler>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      <InputHandler form_data={form_data} setFormData={setFormData} Name="contact_number" Type="text" Label="Organization Contact Number"></InputHandler>
                    </div>
                    <div className='col'>
                      <InputHandler form_data={form_data} setFormData={setFormData} Name="website" Type="text" Label="Organization Website"></InputHandler>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      <InputHandler form_data={form_data} setFormData={setFormData} Name="longitude" Type="text" Label="Longitude"></InputHandler>
                    </div>
                    <div className='col'>
                      <InputHandler form_data={form_data} setFormData={setFormData} Name="latitude" Type="text" Label="Latitude"></InputHandler>
                    </div>
                  </div>
                  {/* <TextareaHandler form_data={form_data} setFormData={setFormData} Name="content" Type="text" Label="Announcement Description"></TextareaHandler> */}
                </div>
              </div>
            </FormSubmissionHandler>
          </div>
        </div >
      </div >
    </>
  )
}
