import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import InputHandler from '../../../../UIComponents/InputHandler'
import FormSubmissionHandler from '../../../../UIComponents/FormSubmissionHandler'
import { postAPI } from '../../../../utils/Api';

import { store } from '../../../../app/store'
import { login } from '../../../../auth/authSlice';
import { useDispatch } from 'react-redux';

export default function Index() {
  const user = store.getState().auth.user;
  const dispatch = useDispatch();

  const InitialFormData = {}
  const [form_data, setFormData] = useState(InitialFormData);
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState({});



  useEffect(() => {
    if (user?.token) {
      window.location.href = '/Dashboard'
    }
  }, [user])

  const handleSubmit = async e => {
    e.preventDefault()
    console.log("form_data", form_data)
    const response = await postAPI('/Users/loginByOTP', form_data);
    if (response?.status == 1) {
      const user = response?.data
      console.log('/Users/loginByOTP', response)
      dispatch(login({ ...user, user: { ...user, role: user?.role, username: form_data?.username }, token: response?.token }));
      window.location.href = "/OTP"
    } else {
      alert(response?.error)
    }
  }


  return (
    <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="card-group d-block d-md-flex row">
              <div className="card col-md-7 p-4 mb-0">
                <div className="card-body">
                  <h1 style={{ color: "rgb(88, 86, 214)" }}>Login</h1>
                  <FormSubmissionHandler handleSubmit={handleSubmit} editing={editing} error={error}>
                    <InputHandler form_data={form_data} setFormData={setFormData} Name="username" Type="text" Label="Username"></InputHandler>
                    <InputHandler form_data={form_data} setFormData={setFormData} Name="password" Type="password" Label="Password"></InputHandler>
                  </FormSubmissionHandler>
                  <div className="row">
                    <div className="col-12 text-end">
                      <button className="btn btn-link px-0" type="button" onClick={() => window.location.href = "/ForgotPassword"}>Forgot password?</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card col-md-5 text-white bg-primary py-5">
                <div className="card-body text-center">
                  <div>
                    <h2>Sign Up</h2>
                    <button className="btn btn-lg btn-outline-light mt-3" type="button" onClick={() => window.location.href = "/register"}>Register Now!</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
